import React, { useEffect, useMemo, useState } from 'react';
import { request } from '#api';
import Form, { FormSection, InputText, InputMultiline, InputDate, Select, Checkbox, InputMoney, File } from '#Form';
import Dialog from '#Dialog';
import { msgOk } from '#notify';

import {
	reqCompensationOptions,
	exchangeReasonOptions,
	defectCategoryOptions,
	producerOptions,
} from './schemas'
import DialogTitle from './DialogTitle';

const DEFAULT_STARTUP_DATA = {
	exchangeReason: 'defective',
	// device: {
	// 	producer: 'aht',
	// },

	customer: { name:'Lidl' },
	complaintDate: '2024-07-01',
	repairDate: '2024-07-02',
	notificationToAHTDate: '2024-07-03',
	responsibleEmail: 'test@test.com',
	reqCompensation: reqCompensationOptions[1].value,
	defectCategory: defectCategoryOptions[1].value,
	device: { producer:'aht', serial:'dev-serial', name:'dev-name' },
	store: {
		name: 'store-name',
		address: 'store-x',
		zip: '012345',
		city: 'Frankfurt',
		country: 'DE',
	}
}

export const RequestForm = ({ _id }) => {
	const [ data, setData ] = useState({})

	useEffect(() => {
		_id ? request('/cabinet-exchange/get', { _id }).then(setData) : setData(DEFAULT_STARTUP_DATA)
	}, [ _id ])

	const onSubmit = async () => {
		await request('/cabinet-exchange/submit', data)
		setData(DEFAULT_STARTUP_DATA)
		msgOk('Request submitted succesfully')
	}

	const formProps = {
		data, setData,
		onSubmit: _id ? undefined : onSubmit,
		submitLabel: 'Submit request',
	}

	const isRO = useMemo(() => {
		return Boolean(_id)
	}, [ _id ])

	return (
		<Form {...formProps}>
			<FormSection title="Customer">
				<InputText label="Customer name" name="customer.name" required disabled={isRO} />
				<InputText label="Customer number" name="customer.number" disabled={isRO} />
				<InputText label="Contact information" name="customer.contact" disabled={isRO} />
				<InputText label="Responsible person email" name="responsibleEmail" required disabled={isRO} />
				<InputDate label="Complaint date" name="complaintDate" required disabled={isRO} />
				<InputDate label="Date of repair" name="repairDate" required disabled={isRO} />
				<InputDate label="Notification date to AHT" name="notificationToAHTDate" required disabled={isRO} />
			</FormSection>

			<FormSection title="Device">
				<Select required label="Procucer" options={producerOptions} name="device.producer" disabled={isRO} />
				<InputText required label="Serial number" name="device.serial" disabled={isRO} />
				<InputText required label="Device name" name="device.name" disabled={isRO} />
			</FormSection>

			<FormSection title="Device location">
				<InputText label="Store number" name="store.name" required disabled={isRO} />
				<InputText label="Address" name="store.address" required disabled={isRO} />
				<InputText label="ZIP code" name="store.zip" required disabled={isRO} />
				<InputText label="City" name="store.city" required disabled={isRO} />
				<InputText label="Country" name="store.country" required disabled={isRO} />
				<InputText label="Phone number" name="store.phone" disabled={isRO} />
				<Checkbox label="Different delivery address" name="differentDelivery" disabled={isRO} />
				{ Boolean(data?.differentDelivery) && (
					<>
						<InputText label="Delivery location" name="delivery.name" required disabled={isRO} />
						<InputText label="Address" name="delivery.address" required disabled={isRO} />
						<InputText label="ZIP code" name="delivery.zip" required disabled={isRO} />
						<InputText label="City" name="delivery.city" required disabled={isRO} />
						<InputText label="Country" name="delivery.country" requireddisabled={isRO}  />
						<InputText label="Phone number" name="delivery.phone" disabled={isRO} />
					</>
				)}
			</FormSection>

			<FormSection>
				<InputMultiline required label="Technician report" name="technicianReport" disabled={isRO} />
			</FormSection>

			<FormSection>
				<Select label="Desired compensation" name="reqCompensation" options={reqCompensationOptions} required disabled={isRO} />
				<Select label="Reason for exchange" name="exchangeReason" options={exchangeReasonOptions} required disabled={isRO} />
				<Select label="Defect category" name="defectCategory" options={defectCategoryOptions} required disabled={isRO} />
			</FormSection>

			<FormSection>
				<File
					label="Attachments"
					name="attachments"
					uploadEndpoint="/cabinet-exchange/attachment/add"
					getEndpoint="/cabinet-exchange/attachment/get"
					downloadEndpoint="/cabinet-exchange/attachment/download"
					disabled={isRO}
				/>
			</FormSection>

			{ Boolean(data?.productSupport?.rejectReason) && (
				<FormSection title="Product support">
					<InputMultiline label="Reject reason" name="productSupport.rejectReason" disabled />
				</FormSection>
			)}
			{ Boolean(data?.serviceManagement?.rejectReason) && (
				<FormSection title="Service management">
					<InputMultiline label="Reject reason" name="serviceManagement.rejectReason" disabled />
				</FormSection>
			)}

			{ data.hasOwnProperty('spareParts') && (
				<FormSection title="Spare parts">
					<InputText label="OC delivery number" name="spareParts.ocDeliveryNumber" disabled />
					<InputText label="OC return number" name="spareParts.ocReturnNumber" disabled />
					<InputText label="Credit note number" name="spareParts.creditNoteNumber" disabled />
					<InputMoney label="Credit note amount" name="spareParts.creditNoteAmount" disabled />
				</FormSection>
			)}
		</Form>
	)
}

export const RequestDialog = ({ _id, item, handleClose }) => {
	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title="Exchange request" />}
			width="lg"
		>
			<RequestForm _id={_id} />
		</Dialog>
	)
}
